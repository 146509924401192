<template>
    <div class="pt-6">
        <v-container fluid>
            <v-row>
                <v-col cols="12"> <h1 class="text-h4">Nytt arrangement</h1></v-col>
                <v-col cols="12"> </v-col>
            </v-row>
            <tab-general v-model="aktivitet"></tab-general>
        </v-container>
    </div>
</template>

<script>
import TabGeneral from '@/pages/activities/TabGeneral';

export default {
    name: 'ActivityNew',
    components: {
        TabGeneral,
    },
    data() {
        return {
            valid: true,

            aktivitet: {
                tittel: '',
                aktivitetTypeId: null,
                moteromId: null,
                sted: '',
                start: null,
                start_tid: null,
                slutt: null,
                slutt_tid: null,
                publisert: null,
                paameldingsfrist: null,
                antallPlasser: '',
                visDeltakere: false,
            },
        };
    },
};
</script>
